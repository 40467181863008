import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import CustomLoader from '../../../../components/ui/Loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import chroma from 'chroma-js';
import moment from 'moment';
import Event from './Event';
import DayWrapper from './DayWrapper';
import Toolbar from './Toolbar';
import WorkOnNationalHolidayModal from '../../../../components/modals/WorkOnNationalHolidayModal';
import { isSameDay, isWithinRange } from 'date-fns';
import { formatDate } from '../../../../utils/Date';
import '../../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import {
  toggleRequestModal,
  setRequestModalInitialState,
} from '../../../../redux/actions/uiActions';
import {
  fetchAvailableVacations,
  checkForNationalHolidayVacationRequest,
  deleteRequest,
} from '../../../../redux/actions/signedInUserVacationsActions';
import { CALENDARS, CALENDAR_COLORS } from '../../../../constants';

const localizer = BigCalendar.momentLocalizer(moment);
moment.locale('ko', {
  week: {
    dow: 1,
  },
});
BigCalendar.momentLocalizer(moment);

let components = {
  event: Event,
  dayWrapper: DayWrapper,
  toolbar: Toolbar,
};

class Calendar extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    showWorkOnNationalHolidayModal: false,
    nationalHolidayName: '',
    nationalHolidayDate: '',
    vacationId: 0,
  };

  toggleWorkOnNationalHolidayModal = () => {
    this.setState({
      showWorkOnNationalHolidayModal: !this.state.showWorkOnNationalHolidayModal,
    });
  };

  isNationalHoliday = (startDate, endDate) => {
    return this.props.nationalHolidays.find(
      v => v.startDate === startDate || v.endDate === endDate,
    );
  };

  onSubmitWorkOnNationalHoliday = () => {
    // in lieu "creation" (delete the system-assigned vacation)
    this.props.deleteRequest(this.state.vacationId, 'dashboard', '', '');
    this.toggleWorkOnNationalHolidayModal();
  };

  vacationRequestPresentOnNationalHolidayCallback = data => {
    const { name, startDate, endDate, vacationId } = data;
    const formattedStartDate = formatDate(startDate, 'D MMM YYYY');
    const formattedEndDate = formatDate(endDate, 'D MMM YYYY');
    this.setState(
      {
        nationalHolidayName: name,
        nationalHolidayDate: `${formattedStartDate} - ${formattedEndDate}`,
        vacationId,
      },
      this.toggleWorkOnNationalHolidayModal(),
    );
  };

  onSelectSlot = slotInfo => {
    const { selectedCalendar } = this.props;

    if (selectedCalendar === CALENDARS.vacations) {
      const startDate = formatDate(slotInfo.start, 'YYYY-MM-DD');
      const endDate = formatDate(slotInfo.end, 'YYYY-MM-DD');
      const year = slotInfo.start.getFullYear();

      const isNationallHoliday = this.isNationalHoliday(startDate, endDate);

      if (isNationallHoliday) {
        // check if system-created vacation request exists
        // (the day hasn't been previously used inLieu)
        this.props.checkForNationalHolidayVacationRequest(
          this.props.signedInUser.id,
          isNationallHoliday.id,
          year,
          this.vacationRequestPresentOnNationalHolidayCallback,
          () => this.openRequestVacationModal(slotInfo.start, slotInfo.end),
        );
      } else {
        this.openRequestVacationModal(slotInfo.start, slotInfo.end);
      }
    }
  };

  openRequestVacationModal = (startDate, endDate) => {
    const year = startDate.getFullYear();
    this.props.setRequestModalInitialState({
      range: {
        range1: { startDate, endDate },
      },
    });
    this.props.fetchAvailableVacations(this.props.signedInUser.id, { year });
    this.props.toggleRequestModal();
  };

  endAccessor = event => {
    const endDate = new Date(event.endDate);
    const userTimezoneOffset = endDate.getTimezoneOffset() / 60 + 1;
    endDate.setHours(endDate.getHours() + userTimezoneOffset);
    return endDate;
  };

  startAccessor = event => {
    const startDate = new Date(event.startDate);
    const userTimezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + userTimezoneOffset);
    return startDate;
  };

  eventPropGetter = event => {
    const eventColor = event.vacationTypeColor || CALENDAR_COLORS.defaultEvent;
    const isFirstYear = event.type === 'anniversary' && event.years === 0;
    const anniversaryColor = isFirstYear
      ? CALENDAR_COLORS.firstYearAnniversary
      : CALENDAR_COLORS.anniversary;

    // default event props
    const eventProps = {
      className: 'vacationEvent',
      style: {
        order: 3,
        backgroundColor: chroma(eventColor)
          .alpha(0.6)
          .css(),
      },
    };
    event.userRights = this.props.userRights;

    // update event props based on event type
    if (event.vacationType === 'nationalHoliday') {
      eventProps.className = 'nationalHolidayEvent';
      eventProps.style.order = 2;
      delete eventProps.style.backgroundColor;
    }

    if (['anniversary', 'birthday'].includes(event.type)) {
      eventProps.className = 'anniversaryEvent';
      eventProps.style.order = 2;
      eventProps.style.backgroundColor =
        event.type === 'anniversary'
          ? chroma(anniversaryColor)
              .alpha(0.8)
              .css()
          : chroma(CALENDAR_COLORS.birthday)
              .alpha(0.8)
              .css();
    }

    return eventProps;
  };

  dayPropGetter = event => {
    const { selectedCalendar, nationalHolidays } = this.props;
    let isHoliday = false;
    let isWeekend = false;
    const currentCalendarDay = new Date(event);
    nationalHolidays.map(holiday => {
      if (
        (isSameDay(holiday.startDate, currentCalendarDay) ||
          isSameDay(holiday.endDate, currentCalendarDay) ||
          isWithinRange(currentCalendarDay, holiday.startDate, holiday.endDate)) &&
        selectedCalendar === CALENDARS.vacations
      ) {
        isHoliday = true;
      }
      return null;
    });
    if (currentCalendarDay.getDay() === 6 || currentCalendarDay.getDay() === 0) {
      isWeekend = true;
    }
    if (isHoliday && !isWeekend) {
      return { className: 'nationalHoliday' };
    } else if (isHoliday && isWeekend) {
      return { className: 'nationalHoliday weekEnd' };
    } else if (!isHoliday && isWeekend) {
      return { className: 'weekEnd' };
    } else {
      return null;
    }
  };

  render() {
    const { nationalHolidayName, nationalHolidayDate, showWorkOnNationalHolidayModal } = this.state;
    const { selectedCalendar, dashboardVacations, dashboardAnniversaries, isFetching } = this.props;
    const displayedEvents =
      selectedCalendar === CALENDARS.vacations ? dashboardVacations : dashboardAnniversaries;

    if (isFetching) {
      return (
        <div className="away-calendar">
          <CustomLoader />
        </div>
      );
    }
    return (
      <div className="away-calendar">
        <BigCalendar
          eventPropGetter={this.eventPropGetter}
          localizer={localizer}
          components={components}
          selectable
          dayPropGetter={this.dayPropGetter}
          popup
          events={displayedEvents}
          defaultView="month"
          views={['month']}
          defaultDate={this.props.calendarDate}
          scrollToTime={new Date(2008, 1, 1, 6)}
          onSelectSlot={slotInfo => this.onSelectSlot(slotInfo)}
          endAccessor={this.endAccessor}
          startAccessor={this.startAccessor}
        />
        <WorkOnNationalHolidayModal
          showModal={showWorkOnNationalHolidayModal}
          toggle={this.toggleWorkOnNationalHolidayModal}
          nationalHolidayName={nationalHolidayName}
          nationalHolidayDate={nationalHolidayDate}
          onSubmit={this.onSubmitWorkOnNationalHoliday}
        />
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  showRequestModal: store.ui.showRequestModal,
  dashboardVacations: store.dashboardVacations.list,
  dashboardAnniversaries: store.dashboardVacations.anniversaries,
  isFetching: store.dashboardVacations.isFetching,
  selectedCalendar: store.ui.selectedCalendar,
  selectedVacationType: store.ui.selectedVacationType,
  selectedStudio: store.ui.selectedStudio,
  calendarDate: store.ui.calendarDate,
  signedInUser: store.signedInUser.user,
  userRights: store.signedInUser.userRights,
  nationalHolidays: store.dashboardVacations.list.filter(
    vacation => vacation.vacationType === 'nationalHoliday',
  ),
});
const mapDispatchToProps = dispatch => ({
  toggleRequestModal: bindActionCreators(toggleRequestModal, dispatch),
  setRequestModalInitialState: bindActionCreators(setRequestModalInitialState, dispatch),
  fetchAvailableVacations: bindActionCreators(fetchAvailableVacations, dispatch),
  checkForNationalHolidayVacationRequest: bindActionCreators(
    checkForNationalHolidayVacationRequest,
    dispatch,
  ),
  deleteRequest: bindActionCreators(deleteRequest, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Calendar);
