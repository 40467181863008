import React from 'react';
import HolidayList from '../HolidayList';

const FixedHolidays = ({ holidayList, onEdit, onDelete, onCreate }) => {
  return (
    <div className="away-list">
      <div className="list-title-section">
        <h3 className="list-title">Fixed Dates</h3>
        <button className="btn btn-primary btn-small" onClick={() => onCreate(1)}>
          Add
        </button>
      </div>
      <HolidayList holidayList={holidayList} onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
};

export default FixedHolidays;
