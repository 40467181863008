import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/ui/Header';
import DeleteAcceptVacationRequestModal from '../../components/modals/DeleteAcceptVacationRequestModal';
import RejectVacationRequestModal from '../../components/modals/RejectVacationRequestModal';
import VacationRequestsList from './components/VacationRequestsList';
import { fetchTeamVacations } from '../../redux/actions/teamVacationsActions';
import {
  deleteRequest,
  requestAnswer,
  fetchRequest,
  resetRequestFlow,
} from '../../redux/actions/signedInUserVacationsActions';
import { resetRejectVacationRequestModalForm } from '../../redux/actions/uiActions';

class VacationRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: props.teamVacationsPage || 1,
      currentTab: 'pending',
      showConfirmModal: false,
      showRejectVacationRequestModal: false,
      vacationId: 0,
      onSubmitConfirmModal: () => this.onConfirmDelete,
      confirmModalHeader: 'Delete vacation request',
      currentVacation: {},
      clearSearch: false,
      searchValue: '',
    };

    this.handleCurrentTabChange = this.handleCurrentTabChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleEmailLink();
  }

  handleEmailLink = () => {
    const params = new URLSearchParams(this.props.location.search);
    const vacationId = params.get('vacationId');
    const action = params.get('action');
    if (vacationId) {
      this.props.fetchRequest(vacationId, data => {
        if (data) {
          if (action === 'reject') {
            this.setState({ currentVacation: data }, () => {
              this.toggleRejectVacationRequestModal();
            });
          } else {
            if (action === 'approve') {
              //direct approve
              this.setState(
                {
                  currentVacation: data,
                },
                () => {
                  this.onApprove({ id: vacationId, name: data.name });
                },
              );

              //show confirm modal
              // this.setState(
              //   {
              //     onSubmitConfirmModal: () => this.onApprove({ id: vacationId }),
              //     confirmModalHeader: 'Approve vacation request',
              //     currentVacation: data,
              //   },
              //   () => {
              //     this.toggleConfirmModal();
              //   },
              // );
            }
          }
        } else {
          this.cleanParams();
        }
      });
    } else {
      const { currentTab } = this.state;
      this.props.fetchTeamVacations({ status: currentTab });
    }
  };

  handleCurrentTabChange(type) {
    this.setState({
      ...this.state,
      currentTab: type,
      activePage: 1,
      clearSearch: true,
      searchValue: '',
    });

    this.props.fetchTeamVacations({ status: type });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.props.fetchTeamVacations({
      status: this.state.currentTab,
      search: this.state.searchValue,
      page: pageNumber,
    });
  }

  onDelete = data => {
    this.setState(
      {
        vacationId: data.id,
        onSubmitConfirmModal: () => this.onConfirmDelete(),
        confirmModalHeader: 'Delete vacation request',
        currentVacation: data,
      },
      () => {
        this.toggleConfirmModal();
      },
    );
  };

  toggleConfirmModal = () => {
    this.setState({
      showConfirmModal: !this.state.showConfirmModal,
    });
  };

  toggleRejectVacationRequestModal = () => {
    this.props.resetRejectVacationRequestModalForm();
    //on modal close, clean query params
    this.state.showRejectVacationRequestModal && this.cleanParams();
    this.setState({
      showRejectVacationRequestModal: !this.state.showRejectVacationRequestModal,
    });
  };

  onConfirmDelete = () => {
    this.toggleConfirmModal();
    this.props.deleteRequest(this.state.vacationId, 'approvedRequests', this.state.currentTab);
    this.setState({ activePage: 1 });
  };

  onApprove = data => {
    this.props.requestAnswer(data.id, 'approve', {}, this.state.currentTab, data.name);
    this.setState({ activePage: 1 });
    if (this.state.showConfirmModal) {
      this.toggleConfirmModal();
    }
    //clean query parameters
    this.cleanParams();
  };

  cleanParams() {
    this.props.history.push(this.props.history.location.pathname);
    this.props.resetRequestFlow();
  }

  onDeny = data => {
    this.toggleRejectVacationRequestModal();
    this.setState({
      currentVacation: data,
    });
  };

  onConfirmDeny = data => {
    this.toggleRejectVacationRequestModal();
    this.props.requestAnswer(
      this.state.currentVacation.id,
      'reject',
      { reason: data.reason },
      this.state.currentTab,
      this.state.currentVacation.name,
    );
    this.setState({ activePage: 1 });
  };

  handleSearchData = searchData => {
    this.setState({
      searchValue: searchData,
      activePage: 1,
    });
    this.props.fetchTeamVacations({
      status: this.state.currentTab,
      search: searchData,
      page: 1,
    });
  };

  handleResetSearch = () => {
    this.setState({
      ...this.state,
      clearSearch: false,
      searchValue: '',
      activePage: 1,
    });
  };

  render() {
    const { currentTab, activePage, showRejectVacationRequestModal } = this.state;
    const { teamVacationsList, teamVacationsTotal, isFetching } = this.props;

    return (
      <Fragment>
        <Header title="Requests" hasRequestDay />
        <VacationRequestsList
          currentTab={currentTab}
          requestsList={teamVacationsList}
          requestsTotal={teamVacationsTotal}
          handleCurrentTabChange={this.handleCurrentTabChange}
          handleSearchData={this.handleSearchData}
          handleResetSearch={this.handleResetSearch}
          clearSearch={this.state.clearSearch}
          activePage={activePage}
          handlePageChange={this.handlePageChange}
          onDelete={this.onDelete}
          onDeny={this.onDeny}
          onApprove={this.onApprove}
          isFetching={isFetching}
        />
        <DeleteAcceptVacationRequestModal
          vacation={this.state.currentVacation}
          isOpen={this.state.showConfirmModal}
          toggle={this.toggleConfirmModal}
          header={this.state.confirmModalHeader}
          bodyText="Are you sure?"
          onConfirm={this.state.onSubmitConfirmModal}
        />
        <RejectVacationRequestModal
          vacation={this.state.currentVacation}
          showModal={showRejectVacationRequestModal}
          onSubmit={this.onConfirmDeny}
          toggleModal={this.toggleRejectVacationRequestModal}
          initialValues={{
            reason: '',
          }}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  teamVacationsList: store.teamVacations.list,
  teamVacationsPage: store.teamVacations.listPage,
  teamVacationsTotal: store.teamVacations.listTotal,
  isFetching: store.teamVacations.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTeamVacations: bindActionCreators(fetchTeamVacations, dispatch),
  deleteRequest: bindActionCreators(deleteRequest, dispatch),
  requestAnswer: bindActionCreators(requestAnswer, dispatch),
  fetchRequest: bindActionCreators(fetchRequest, dispatch),
  resetRequestFlow: bindActionCreators(resetRequestFlow, dispatch),
  resetRejectVacationRequestModalForm: bindActionCreators(
    resetRejectVacationRequestModalForm,
    dispatch,
  ),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(VacationRequests);
