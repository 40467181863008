import axios from 'axios';
import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  VALIDATE_TOKEN_START,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
  SKIP_TOKEN_VALIDATION,
  FETCH_CONSTANTS_SUCCESS,
  FETCH_CONSTANTS_ERROR,
  FETCH_CONSTANTS_START,
  FETCH_TEAM_USERS_START,
  FETCH_TEAM_USERS_SUCCESS,
  FETCH_TEAM_USERS_ERROR,
  RESET_UI,
} from './types';
import { baseURL, getRequestHeaders } from './api';
import { fetchSignedInUser } from './signedInUserActions';

export const validateToken = token => async dispatch => {
  dispatch({ type: VALIDATE_TOKEN_START });
  try {
    const verifyResponse = await axios.post(`${baseURL}verify`, { token });
    if (verifyResponse.data && verifyResponse.data.status === 'success') {
      localStorage.setItem('token', token);
      dispatch({ type: VALIDATE_TOKEN_SUCCESS, payload: verifyResponse.data });
      dispatch(fetchConstants());
      dispatch(fetchSignedInUser());
      dispatch(fetchTeamAllUsers());
    }
  } catch (e) {
    dispatch({ type: VALIDATE_TOKEN_ERROR });
  }
};

export const skipTokenValidation = () => dispatch => {
  dispatch({ type: SKIP_TOKEN_VALIDATION });
};

export const loginUser = user => async dispatch => {
  dispatch({ type: LOGIN_USER_START });
  try {
    const loginResponse = await axios.post(`${baseURL}login`, user);
    if (loginResponse.data && loginResponse.data.status === 'success') {
      localStorage.setItem('token', loginResponse.data.token);
      dispatch({ type: LOGIN_USER_SUCCESS, payload: loginResponse.data });
      dispatch(fetchConstants());
      dispatch(fetchSignedInUser());
      dispatch(fetchTeamAllUsers());
    }
  } catch (e) {
    dispatch({ type: LOGIN_USER_ERROR });
  }
};

export const logoutUser = () => async dispatch => {
  const token = localStorage.getItem('token');
  dispatch({ type: LOGOUT_USER });
  dispatch({ type: RESET_UI });
  try {
    await axios.post(`${baseURL}logout`, { token }, getRequestHeaders());
    localStorage.removeItem('token');
  } catch (error) {
    console.log(error);
  }
};

export const fetchConstants = () => async dispatch => {
  dispatch({ type: FETCH_CONSTANTS_START });
  try {
    const constantsResponse = await axios.get(`${baseURL}api/types`, getRequestHeaders());
    const teamsResponse = await axios.get(`${baseURL}api/settings/teams`, getRequestHeaders());
    if (constantsResponse.data && teamsResponse.data) {
      const payload = { ...constantsResponse.data, teams: teamsResponse.data.data };
      dispatch({
        type: FETCH_CONSTANTS_SUCCESS,
        payload,
      });
    }
  } catch (e) {
    dispatch({ type: FETCH_CONSTANTS_ERROR });
  }
};

export const fetchTeamAllUsers = () => async dispatch => {
  dispatch({ type: FETCH_TEAM_USERS_START });

  try {
    const teamAllUsersResponse = await axios.get(`${baseURL}api/team/members`, getRequestHeaders());
    if (teamAllUsersResponse.data) {
      dispatch({ type: FETCH_TEAM_USERS_SUCCESS, payload: teamAllUsersResponse.data });
    }
  } catch (e) {
    dispatch({ type: FETCH_TEAM_USERS_ERROR });
  }
};
