import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleRequestModal, setRequestModalInitialState } from '../../../redux/actions/uiActions';
import RequestModal from '../../modals/RequestModal';
import { BackArrowIcon } from '../SvgIcons';
import { fetchAvailableVacations } from '../../../redux/actions/signedInUserVacationsActions';
import './header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title || '',
    };

    this.showRequestModal = this.showRequestModal.bind(this);
  }

  showRequestModal = () => {
    this.props.setRequestModalInitialState({
      range: {
        range1: {
          startDate: new Date(),
          endDate: new Date(),
        },
      },
      note: '',
      user: null,
      vacationType: null,
    });
    this.props.fetchAvailableVacations(this.props.signedInUser.id);
    this.props.toggleRequestModal();
  };

  render() {
    const {
      title,
      hasRequestDay,
      hasBack,
      onBack,
      showRequestModal,
      toggleRequestModal,
      initialValues,
      children,
    } = this.props;

    return (
      <div className="away-header">
        <h2>
          {hasBack && (
            <span className="back" onClick={onBack}>
              <BackArrowIcon />
            </span>
          )}
          {title}
        </h2>
        {hasRequestDay && (
          <div style={{ display: 'flex' }}>
            {children}

            <Button onClick={this.showRequestModal} color="primary">
              Request Day(s) Off
            </Button>

            <RequestModal
              showModal={showRequestModal}
              initialValues={initialValues}
              toggleModal={toggleRequestModal}
            />
          </div>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  hasRequestDay: PropTypes.bool,
  hasBack: PropTypes.bool,
  onBack: PropTypes.func,
};

const mapStoreToProps = store => ({
  showRequestModal: store.ui.requestModal.showRequestModal,
  initialValues: store.ui.requestModal.initialValues,
  signedInUser: store.signedInUser.user,
});
const mapDispatchToProps = dispatch => ({
  toggleRequestModal: bindActionCreators(toggleRequestModal, dispatch),
  fetchAvailableVacations: bindActionCreators(fetchAvailableVacations, dispatch),
  setRequestModalInitialState: bindActionCreators(setRequestModalInitialState, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Header);
