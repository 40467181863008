import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/ui/Header';
import ConfirmModal from '../../components/modals/ConfirmModal';
import HistoryRequestsList from './components/HistoryRequestsList';
import {
  fetchSignedInUserVacations,
  fetchAvailableVacations,
} from '../../redux/actions/signedInUserVacationsActions';
import { deleteRequest } from '../../redux/actions/signedInUserVacationsActions';
import {
  toggleRequestModal,
  setRequestModalInitialState,
  setRequestModalVacationId,
} from '../../redux/actions/uiActions';

class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: props.signedInUserVacationsPage || 1,
      currentTab: 'pending',
      showRequestModal: false,
      currentVacation: {},
      showDeleteModal: false,
      vacationId: 0,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleCurrentTabChange = this.handleCurrentTabChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  componentDidMount() {
    const { currentTab } = this.state;
    this.props.fetchSignedInUserVacations(currentTab);
  }

  handleCurrentTabChange(type) {
    this.setState({
      ...this.state,
      currentTab: type,
      activePage: 1,
    });

    this.props.fetchSignedInUserVacations(type);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.props.fetchSignedInUserVacations(this.state.currentTab, pageNumber);
  }

  toggleModal() {
    this.setState({
      showRequestModal: !this.state.showRequestModal,
    });
  }

  onDelete = data => {
    this.setState({
      vacationId: data.id,
    });
    this.toggleDeleteModal();
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  onConfirmDelete = () => {
    this.toggleDeleteModal();
    this.props.deleteRequest(this.state.vacationId, 'history', this.state.currentTab);
    this.setState({ activePage: 1 });
  };

  fetchStatsCallback = () => {
    const { currentVacation } = this.state;
    const { vacationTypesStats } = this.props;
    const selectedVacation = vacationTypesStats.find(
      v => v.value === currentVacation.vacationTypeId,
    );

    // preselect in lieu if vacation has an inLieuId
    let inLieuInitialValues = {};
    if (currentVacation.inLieuId && currentVacation.inLieuLabel) {
      inLieuInitialValues = {
        inLieuCheckbox: true,
        inLieu: {
          value: currentVacation.inLieuId,
          label: currentVacation.inLieuLabel,
        },
      };
    }

    this.props.setRequestModalInitialState({
      range: {
        range1: {
          startDate: new Date(currentVacation.startDate),
          endDate: new Date(currentVacation.endDate),
        },
      },
      vacationType: selectedVacation,
      note: currentVacation.note,
      ...inLieuInitialValues,
    });

    this.props.toggleRequestModal();
  };

  onEdit = data => {
    this.setState({
      currentVacation: data,
    });
    let year = new Date().getFullYear();
    if (data && data.startDate) {
      year = new Date(data.startDate).getFullYear();
    }
    this.props.setRequestModalVacationId(data.id);
    this.props.fetchAvailableVacations(
      this.props.signedInUser.id,
      { excludeVacationId: data.id, year },
      this.fetchStatsCallback,
    );
  };

  render() {
    const { currentTab, showRequestModal, activePage } = this.state;
    const { signedInUserVacationsList, signedInUserVacationsTotal, isFetching } = this.props;

    const requestList = (
      <HistoryRequestsList
        currentTab={currentTab}
        requestsList={signedInUserVacationsList}
        requestsTotal={signedInUserVacationsTotal}
        showRequestModal={showRequestModal}
        toggleModal={this.toggleModal}
        handleCurrentTabChange={this.handleCurrentTabChange}
        activePage={activePage}
        handlePageChange={this.handlePageChange}
        onDelete={this.onDelete}
        onEdit={this.onEdit}
        isFetching={isFetching}
      />
    );

    return (
      <Fragment>
        <Header title="My History" hasRequestDay />
        {requestList}
        <ConfirmModal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
          header="Delete vacation request"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  signedInUserVacationsList: store.signedInUserVacations.list,
  signedInUserVacationsPage: store.signedInUserVacations.listPage,
  signedInUserVacationsTotal: store.signedInUserVacations.listTotal,
  isFetching: store.signedInUserVacations.isFetching,
  signedInUser: store.signedInUser.user,
  showRequestModal: store.ui.showRequestModal,
  vacationTypesStats: store.signedInUserVacations.availableVacations.vacations,
  inLieuStats: store.signedInUserVacations.availableVacations.inLieu,
});

const mapDispatchToProps = dispatch => ({
  fetchSignedInUserVacations: bindActionCreators(fetchSignedInUserVacations, dispatch),
  deleteRequest: bindActionCreators(deleteRequest, dispatch),
  toggleRequestModal: bindActionCreators(toggleRequestModal, dispatch),
  setRequestModalInitialState: bindActionCreators(setRequestModalInitialState, dispatch),
  fetchAvailableVacations: bindActionCreators(fetchAvailableVacations, dispatch),
  setRequestModalVacationId: bindActionCreators(setRequestModalVacationId, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(History);
