import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { bindActionCreators } from 'redux';
import { updateSignedInUserWorkingLocation } from '../../../redux/actions/signedInUserActions';
import { fetchDashboardWFH } from '../../../redux/actions/dashboardVacationsActions';
import './SwitchWorkingLocation.scss';

const WORKING_LOCATION_MAP = {
  false: 0,
  true: 1,
};

// OFFICE = 0
// HOME = 1

class SwitchWorkingLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.user.working_location === 1,
    };
  }

  componentDidUpdate() {
    const { isChecked } = this.state;
    const { user, isUpdating } = this.props;
    const { working_location } = user;
    const shouldBeChecked = working_location === 1;
    if (isChecked !== shouldBeChecked && !isUpdating) {
      this.syncWorkLocation(isChecked);
    }
  }

  syncWorkLocation = async value => {
    const {
      user,
      updateSignedInUserWorkingLocation,
      fetchDashboardWFH,
      selectedStudio,
    } = this.props;
    const { id } = user;
    const payload = { working_location: WORKING_LOCATION_MAP[value] };
    await updateSignedInUserWorkingLocation(id, payload);
    await fetchDashboardWFH(selectedStudio);
  };

  handleChange = value => {
    this.setState({ isChecked: value });
  };

  render() {
    const { isChecked } = this.state;
    const { isUpdating, isMobile } = this.props;

    const labelText = isMobile ? 'WFH' : 'Working From Home';

    return (
      <div className="switch-working-location">
        <label className="switch-working-location-label">{labelText}</label>
        <div className="switch-container">
          <Switch
            checked={isChecked}
            onChange={this.handleChange}
            onColor="#3be9ab"
            onHandleColor="#fff"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={26}
            width={55}
            className="react-switch"
            id="wfh-switch"
            disabled={isUpdating}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  user: store.signedInUser.user,
  isUpdating: store.signedInUser.isUpdatingWorkingLocation,
  selectedStudio: store.ui.selectedStudio,
});

const mapDispatchToProps = dispatch => ({
  updateSignedInUserWorkingLocation: bindActionCreators(
    updateSignedInUserWorkingLocation,
    dispatch,
  ),
  fetchDashboardWFH: bindActionCreators(fetchDashboardWFH, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SwitchWorkingLocation);
