import React from 'react';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import { formatDate } from '../../../../../../utils/Date';

const HolidayListItem = ({ holidayData, isFixed, onEdit, onDelete }) => {
  const editBtn = (
    <span className="edit-icon" onClick={() => onEdit(holidayData)}>
      <FontAwesome name="edit" className="fa-small" />
    </span>
  );

  const deleteBtn = (
    <span className="delete-icon" onClick={() => onDelete(holidayData)}>
      <FontAwesome name="trash" className="fa-small" />
    </span>
  );

  const startDateRendered = !!holidayData.fixed_date
    ? formatDate(holidayData.start_date, 'DD/MMM')
    : formatDate(holidayData.start_date, 'DD-MM-YYYY');

  return (
    <div className="list-item">
      <Row className="li-row">
        <Col md={{ size: 4 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Holiday</span>
        </Col>
        <Col md={{ size: 4 }} className="li-cell">
          {holidayData.name}
        </Col>
        <Col md={{ size: 3 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Duration</span>
        </Col>
        <Col md={{ size: 3 }} className="li-cell">
          {holidayData.work_days}
        </Col>
        <Col md={{ size: 3 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Date</span>
        </Col>
        <Col md={{ size: 3 }} className="li-cell">
          {startDateRendered}
        </Col>
        <Col md={{ size: 2 }} className="li-cell li-cell-end">
          <div className="li-action-buttons">
            {editBtn}
            {deleteBtn}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HolidayListItem;
