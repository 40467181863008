import React from 'react';
import { Row, Col } from 'reactstrap';
import HolidayListItem from '../HolidayListItem';

const HolidayList = ({ holidayList, onEdit, onDelete }) => {
  const holidays =
    holidayList &&
    holidayList.length &&
    holidayList.map((holiday, i) => (
      <HolidayListItem key={i} holidayData={holiday} onEdit={onEdit} onDelete={onDelete} />
    ));

  return (
    <div>
      <Row>
        <Col md={{ size: 12 }}>
          <div className="list-header">
            <Row>
              <Col md={{ size: 4 }} className="list-cell">
                <span className="list-cell-text">Holiday</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Duration</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Date</span>
              </Col>
              <Col md={{ size: 2 }} className="list-cell" />
            </Row>
          </div>
        </Col>
      </Row>
      {holidays}
    </div>
  );
};

export default HolidayList;
