import {
  FETCH_OFFICIAL_HOLIDAYS_LIST_START,
  FETCH_OFFICIAL_HOLIDAYS_LIST_SUCCESS,
  FETCH_OFFICIAL_HOLIDAYS_LIST_ERROR,
  ADD_OFFICIAL_HOLIDAY_START,
  ADD_OFFICIAL_HOLIDAY_SUCCESS,
  ADD_OFFICIAL_HOLIDAY_ERROR,
  UPDATE_OFFICIAL_HOLIDAY_START,
  UPDATE_OFFICIAL_HOLIDAY_SUCCESS,
  UPDATE_OFFICIAL_HOLIDAY_ERROR,
  DELETE_OFFICIAL_HOLIDAY_START,
  DELETE_OFFICIAL_HOLIDAY_SUCCESS,
  DELETE_OFFICIAL_HOLIDAY_ERROR,
  REMOVE_FIXED_HOLIDAY,
  REMOVE_FLOATIING_HOLIDAY,
  CLEAN_REQUEST_ERRORS,
} from './types';
import { getData, postData, putData, deleteData, baseURL } from './api';
import { setGeneralAlertMessage } from '../actions/uiActions';
import { reset } from 'redux-form';

export const fetchOfficialHolidaysList = params => dispatch => {
  dispatch({ type: FETCH_OFFICIAL_HOLIDAYS_LIST_START });

  return getData(
    `${baseURL}/api/settings/officialHolidays`,
    params,
    FETCH_OFFICIAL_HOLIDAYS_LIST_SUCCESS,
    FETCH_OFFICIAL_HOLIDAYS_LIST_ERROR,
    dispatch,
  );
};

export const addOfficialHoliday = params => dispatch => {
  dispatch({ type: ADD_OFFICIAL_HOLIDAY_START });
  postData(
    `${baseURL}api/settings/officialHoliday`,
    params,
    ADD_OFFICIAL_HOLIDAY_SUCCESS,
    ADD_OFFICIAL_HOLIDAY_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch(setGeneralAlertMessage('New holiday added', 'info'));
      dispatch(reset('HolidayModalForm'));
    },
  );
};

export const updateOfficialHoliday = data => dispatch => {
  dispatch({ type: UPDATE_OFFICIAL_HOLIDAY_START });
  return putData(
    `${baseURL}api/settings/officialHoliday/${data.id}`,
    data,
    UPDATE_OFFICIAL_HOLIDAY_SUCCESS,
    UPDATE_OFFICIAL_HOLIDAY_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch(setGeneralAlertMessage('Holiday updated', 'info'));
      dispatch(reset('HolidayModalForm'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const deleteOfficialHoliday = (holidayId, holidayType) => dispatch => {
  dispatch({ type: DELETE_OFFICIAL_HOLIDAY_START, payload: holidayId });
  deleteData(
    `${baseURL}api/settings/officialHoliday/${holidayId}`,
    DELETE_OFFICIAL_HOLIDAY_SUCCESS,
    DELETE_OFFICIAL_HOLIDAY_ERROR,
    dispatch,
    () => {
      holidayType
        ? dispatch({ type: REMOVE_FIXED_HOLIDAY, payload: holidayId })
        : dispatch({ type: REMOVE_FLOATIING_HOLIDAY, payload: holidayId });
      dispatch(setGeneralAlertMessage('Holiday deleted', 'info'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};
