import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomLoader from '../../../../components/ui/Loader';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import VacationTypesList from './components/VacationTypesList';
import VacationTypeModal from './components/VacationTypeModal';

import {
  fetchVacationTypesList,
  deleteVacationType,
} from '../../../../redux/actions/vacationTypeSettingsActions';

class VacationTypesSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVacationTypesModal: false,
      showDeleteModal: false,
      isEditMode: false,
      selectedVacationType: {
        id: 0,
        name: '',
        timesheet_label: '',
        value: 0,
        color_code: {
          hex: '',
        },
      },
      vacationTypeId: 0,
    };

    this.toggleModal = this.toggleModal.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const { fetchVacationTypesList } = this.props;
    fetchVacationTypesList();
  }

  toggleModal = () => {
    this.setState({
      showVacationTypesModal: !this.state.showVacationTypesModal,
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  handleCreate = () => {
    this.setState({
      ...this.state,
      isEditMode: false,
      selectedVacationType: {
        id: 0,
        name: '',
        timesheet_label: '',
        value: 0,
        color_code: {
          hex: '',
        },
      },
    });
    this.toggleModal();
  };

  handleEdit = data => {
    this.setState({
      ...this.state,
      isEditMode: true,
      selectedVacationType: {
        id: data.id,
        name: data.name,
        timesheet_label: data.timesheet_label,
        value: data.value,
        color_code: {
          hex: data.color_code,
        },
      },
    });
    this.toggleModal();
  };

  handleDelete = data => {
    this.setState({
      ...this.state,
      vacationTypeId: data.id,
    });
    this.toggleDeleteModal();
  };

  onConfirmDelete = () => {
    this.toggleDeleteModal();
    this.props.deleteVacationType(this.state.vacationTypeId);
  };

  render() {
    const { isEditMode, showVacationTypesModal, selectedVacationType } = this.state;
    const { vacationTypesList, isFetching } = this.props;

    if (isFetching) {
      return <CustomLoader />;
    }

    return (
      <div>
        <VacationTypesList
          vacationTypesList={vacationTypesList}
          onCreate={this.handleCreate}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
        />
        <VacationTypeModal
          showModal={showVacationTypesModal}
          toggleModal={this.toggleModal}
          edit={isEditMode}
          initialValues={{
            id: selectedVacationType.id,
            name: selectedVacationType.name,
            value: selectedVacationType.value,
            color_code: selectedVacationType.color_code,
            timesheet_label: selectedVacationType.timesheet_label,
          }}
        />
        <ConfirmModal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
          header="Delete vacation type"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  vacationTypesList: store.vacationTypeSettings.vacationTypesList,
  isFetching: store.vacationTypeSettings.isFetching,
});

const mapDispatchToProps = dispatch => ({
  deleteVacationType: bindActionCreators(deleteVacationType, dispatch),
  fetchVacationTypesList: bindActionCreators(fetchVacationTypesList, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(VacationTypesSettings);
