import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import Avatar from '../Avatar';
import Progressbar from '../Progressbar';
import UserProfileModal from '../../modals/UserProfileModal';
import ConfirmModal from '../../modals/ConfirmModal';
import AvatarModal from '../../modals/AvatarModal';
import { formatDate } from '../../../utils/Date';
import { tshirt_sizes } from '../../../constants';
import { fetchSignedInUser, deleteAvatar } from '../../../redux/actions/signedInUserActions';
import './userProfileCard.scss';
import { Link } from 'react-router-dom';

class UserProfileCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userProfileModalOpen: false,
      showAvatarModal: false,
      user_id: 0,
    };

    this.toggleUserProfileModal = this.toggleUserProfileModal.bind(this);
    this.handleSubmitEditProfile = this.handleSubmitEditProfile.bind(this);
    this.toggleAvatarModal = this.toggleAvatarModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  toggleUserProfileModal() {
    this.setState({ userProfileModalOpen: !this.state.userProfileModalOpen });
  }

  handleSubmitEditProfile = data => {
    const { updateSignedInUserProfile } = this.props;
    const payload = {
      ...data,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      company_joined_at: formatDate(data.company_joined_at, 'YYYY-MM-DD'),
      blocked: data.blocked ? 1 : 0,
    };
    updateSignedInUserProfile(payload, () => this.props.fetchSignedInUser());
  };

  toggleAvatarModal = () => {
    this.setState({
      showAvatarModal: !this.state.showAvatarModal,
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  handleDelete = user_id => {
    this.setState({
      ...this.state,
      user_id,
    });
    this.toggleDeleteModal();
  };

  onConfirmDelete = () => {
    this.toggleDeleteModal();
    this.props.deleteAvatar(this.state.user_id);
  };

  render() {
    const { userData, isSignedInUser, userRights, isChangingAvatar } = this.props;

    let teamMembersAvatars;
    if (userData.teamMembers && userData.teamMembers.length) {
      teamMembersAvatars = (
        <Row>
          <Col md={{ size: 12 }}>
            <div className="info-label info-label-big">Team Members</div>
            <div>
              {userData.teamMembers.map((member, i) => {
                return (
                  <Avatar
                    key={i}
                    size="md"
                    title={member.name}
                    avatar={member.avatar}
                    userId={member.id}
                    isLink
                    hasLabel
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <div className="away-info-card">
        <div className="card-shadow">
          <Row>
            <Col md={{ size: 4 }}>
              <Row className="top-row no-margin">
                <Col md="4">
                  <Avatar
                    size="xl"
                    title={userData.name}
                    avatar={userData.avatar}
                    userId={userData.id}
                    isSignedInUser={!!isSignedInUser}
                    isEditable={!!isSignedInUser}
                    onEdit={this.toggleAvatarModal}
                    onDelete={this.handleDelete}
                    isChangingAvatar={isChangingAvatar}
                  />
                </Col>
                <Col md="8">
                  <h3>{userData.name}</h3>
                  <p>{userData.job}</p>
                  {userData.studioLabel && (
                    <span>
                      <FontAwesome name="map-marker" className="fa-small" />
                      {userData.studioLabel}
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="top-row">
                <Col md="12">
                  {isSignedInUser && (
                    <Button className="btn-link" onClick={this.toggleUserProfileModal}>
                      Edit Profile
                    </Button>
                  )}
                </Col>
              </Row>
              {userData.shortDescription && (
                <Row className="desc-row">
                  <Col md="7">
                    <p>{userData.shortDescription}</p>
                  </Col>
                </Row>
              )}
              {(userRights.includes('hasApproverRights') || isSignedInUser) && (
                <Row>
                  <Col md="8">
                    <Progressbar
                      totalDays={userData.total_all}
                      daysLeft={userData.free_all}
                      nrPending={userData.pending_requests}
                      unpaidDaysTaken={userData.taken_unpaid}
                      isContractor={userData.contract === 'self-employed'}
                      hasLabel
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={{ size: 8 }}>
              <Row className="row-space">
                {userData.email && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">E-mail</div>
                    <div className="info-text">{userData.email}</div>
                  </Col>
                )}
                {(userRights.includes('hasApproverRights') || isSignedInUser) &&
                  userData.company_joined_at && (
                    <Col md={{ size: 6 }}>
                      <div className="info-label">Hiring Date</div>
                      <div className="info-text">
                        {formatDate(userData.company_joined_at, 'MMM DD, YYYY')}
                      </div>
                    </Col>
                  )}
                {userData.skype_id && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Skype Id</div>
                    <div className="info-text">{userData.skype_id}</div>
                  </Col>
                )}
                {userData.studioLabel && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Studio</div>
                    <div className="info-text">{userData.studioLabel}</div>
                  </Col>
                )}
                {(userRights.includes('hasApproverRights') || isSignedInUser) &&
                  userData.phone_number && (
                    <Col md={{ size: 6 }}>
                      <div className="info-label">Phone Number</div>
                      <div className="info-text">{userData.phone_number}</div>
                    </Col>
                  )}
                {(userRights.includes('hasApproverRights') || isSignedInUser) &&
                  userData.date_of_birth && (
                    <Col md={{ size: 6 }}>
                      <div className="info-label">Date of Birth</div>
                      <div className="info-text">
                        {formatDate(userData.date_of_birth, 'MMM DD, YYYY')}
                      </div>
                    </Col>
                  )}
                <Col md={{ size: 6 }}>
                  <div className="info-label">Working location</div>
                  <div className="info-text" style={{ color: userData.working_location_color }}>
                    {userData.working_location_label}
                  </div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="info-label">T-shirt size</div>
                  <div className="info-text">
                    {userData.tshirt_size
                      ? tshirt_sizes.find(size => size.value === userData.tshirt_size).label
                      : 'Not specified'}
                  </div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="info-label">US Visa</div>
                  <div className="info-text">{userData.us_visa ? 'Yes' : 'No'}</div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="info-label">NDA Signed</div>
                  <div className="info-text">{userData.nda ? 'Yes' : 'No'}</div>
                </Col>
              </Row>

              {userData.teamLead && userData.teamLead.name && (
                <div>
                  <div className="aic-divider" />
                  <Row>
                    <Col md={{ size: 6 }}>
                      <div className="info-label info-label-big">Team Lead</div>
                      <Avatar
                        size="md"
                        title={userData.teamLead.name}
                        avatar={userData.teamLead.avatar}
                        userId={userData.teamLead.id}
                        isLink
                        hasLabel
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 6 }}>
                      <div className="info-label info-label-big">Teams</div>
                      <div className="info-text">
                        {userData.belongToTeams &&
                          userData.belongToTeams.length !== 0 &&
                          userData.belongToTeams.map(team => {
                            if (userRights.includes('hasApproverRights')) {
                              return (
                                <Link
                                  to="/teams"
                                  key={team.id}
                                  className="btn-link btn btn-secondary"
                                  style={{ paddingLeft: 0 }}
                                >
                                  {team.name}
                                </Link>
                              );
                            }
                            return (
                              <p
                                key={team.id}
                                className="btn-link btn btn-secondary"
                                style={{ paddingLeft: 0 }}
                              >
                                {team.name}
                              </p>
                            );
                          })}
                        {userData.belongToTeams.length === 0 && (
                          <div>{`${userData.name} not yet assigned to any team.`}</div>
                        )}
                        {userData.belongToTeams.length === 0 &&
                          userRights.includes('hasApproverRights') && (
                            <Link
                              to="/team"
                              className="btn-link btn btn-secondary"
                              style={{ paddingLeft: 0 }}
                            >
                              Go to teams
                            </Link>
                          )}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {teamMembersAvatars}
            </Col>
          </Row>
        </div>
        <UserProfileModal
          isSignedInUser={isSignedInUser}
          form="EditProfileForm"
          showModal={this.state.userProfileModalOpen}
          updateUserProfile={this.handleSubmitEditProfile}
          toggleModal={this.toggleUserProfileModal}
          initialValues={{
            id: userData.id,
            email: userData.email,
            company_id: 1,
            company_joined_at: new Date(userData.company_joined_at),
            studio_id: userData.studio_id
              ? { label: userData.studioLabel, value: userData.studio_id }
              : null,
            role: userData.role ? { label: userData.role_label, value: userData.role } : null,
            team_lead:
              userData.teamLead &&
              userData.teamLead.id &&
              typeof userData.teamLead.id === 'number' &&
              userData.teamLead.id > 0
                ? {
                    label: userData.teamLead.name,
                    value: userData.teamLead.id,
                  }
                : null,
            blocked: userData.blocked === 0 ? false : true,
            name: userData.name,
            phone_number: userData.phone_number,
            job: userData.job,
            contract: userData.contract
              ? { label: userData.contract, value: userData.contract }
              : null,
            skype_id: userData.skype_id,
            date_of_birth: new Date(userData.date_of_birth),
            working_location: userData.working_location === 1,
            nda: userData.nda === 1 ? true : false,
            tshirt_size: userData.tshirt_size,
            us_visa: userData.us_visa === 1 ? true : false,
          }}
          destroyOnUnmount={false}
        />
        <AvatarModal
          showModal={this.state.showAvatarModal}
          toggleModal={this.toggleAvatarModal}
          isSignedInUser={isSignedInUser}
          initialValues={{
            user_id: userData.id,
            avatarImg: userData.avatar,
          }}
        />
        <ConfirmModal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
          header="Delete avatar"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

UserProfileCard.propTypes = {
  userData: PropTypes.object,
  updateSignedInUserProfile: PropTypes.func,
};

const mapStoreToProps = store => ({
  userRights: store.signedInUser.userRights,
  isChangingAvatar: store.signedInUser.isChangingAvatar,
});

const mapDispatchToProps = dispatch => ({
  deleteAvatar: bindActionCreators(deleteAvatar, dispatch),
  fetchSignedInUser: bindActionCreators(fetchSignedInUser, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(UserProfileCard);
