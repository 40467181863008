import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { DatePicker, TextInput } from '../../../../../../components/form';
import {
  addOfficialHoliday,
  updateOfficialHoliday,
} from '../../../../../../redux/actions/officialHolidaysSettingsActions';
import { resetHolidayModalForm } from '../../../../../../redux/actions/uiActions';
import { formatDate } from '../../../../../../utils/Date';

import validate from './validations';

class HolidayModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
    };

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
  }

  togglePicker = () => {
    this.setState({
      showDatePicker: !this.state.showDatePicker,
    });
  };

  handleSubmitClicked(data) {
    const { edit, addOfficialHoliday, updateOfficialHoliday } = this.props;

    const requestData = {
      ...data,
      start_date: formatDate(data.start_date, 'YYYY-MM-DD'),
    };

    if (edit) {
      updateOfficialHoliday(requestData);
    } else {
      addOfficialHoliday(requestData);
    }

    this.props.toggleModal();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.resetHolidayModalForm();
  }

  render() {
    const { showModal, handleSubmit, edit } = this.props;

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>{edit ? 'Edit Official Holiday' : 'Add Official Holiday'}</h3>
            </div>

            <div>
              <Field id="holidayName" name="name" label="Holiday Name" component={TextInput} />
            </div>

            <div>
              <Field
                id="duration"
                name="work_days"
                label="Duration"
                type="number"
                component={TextInput}
              />
            </div>

            <div className="away-daterange-inputs" id="datepicker" onClick={this.togglePicker}>
              <Field
                id="datepicker"
                name="start_date"
                component={DatePicker}
                showDatePicker={this.state.showDatePicker}
                togglePicker={this.togglePicker}
              />
            </div>

            <div>
              <Field
                id="dateType"
                name="fixed_date"
                label="Date Type"
                type="hidden"
                component={TextInput}
              />
            </div>

            <div>
              <Field
                id="holidayId"
                name="id"
                label="Holiday Id"
                type="hidden"
                component={TextInput}
              />
            </div>

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Submit
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const selector = formValueSelector('HolidayModalForm');
const HolidayModalForm = reduxForm({
  validate,
  form: 'HolidayModalForm',
  enableReinitialize: true,
})(HolidayModal);

const mapStoreToProps = (store, ownProps) => {
  return {
    date: selector(store, 'date'),
    initialValues: { ...ownProps.initialValues },
  };
};

const mapDispatchToProps = dispatch => ({
  addOfficialHoliday: bindActionCreators(addOfficialHoliday, dispatch),
  updateOfficialHoliday: bindActionCreators(updateOfficialHoliday, dispatch),
  resetHolidayModalForm: bindActionCreators(resetHolidayModalForm, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(HolidayModalForm);
