import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import {
  setSelectedVacation,
  setSelectedStudio,
  setSelectedTeam,
  toggleSelectedCalendar,
} from '../../../../redux/actions/uiActions';
import { firstDayOfAMonth, lastDayOfAMonth } from '../../../../utils/Date';
import {
  fetchDashboardVacations,
  fetchDashboardAnniversaries,
} from '../../../../redux/actions/dashboardVacationsActions';
import { CALENDARS } from '../../../../constants';
import './Toolbar.scss';

class Toolbar extends Component {
  goToBack = () => {
    const { date, onNavigate, userRights } = this.props;
    //date.setMonth(date.getMonth() - 1);
    date.setDate(0);
    this.fetchEvents(date);
    userRights.includes('hasApproverRights') && this.fetchAnniversaries(date);
    onNavigate('prev');
  };

  fetchEvents = date => {
    const firstDay = firstDayOfAMonth(date);
    const lastDay = lastDayOfAMonth(date);
    lastDayOfAMonth(date);
    this.props.fetchDashboardVacations(
      firstDay,
      lastDay,
      this.props.selectedVacationType ? this.props.selectedVacationType.value : 0,
      this.props.selectedStudio ? this.props.selectedStudio.value : 0,
      this.props.selectedTeam ? this.props.selectedTeam.value : 0,
    );
  };

  fetchAnniversaries = date => {
    const firstDay = firstDayOfAMonth(date);
    const lastDay = lastDayOfAMonth(date);
    lastDayOfAMonth(date);
    this.props.fetchDashboardAnniversaries(
      firstDay,
      lastDay,
      this.props.selectedStudio ? this.props.selectedStudio.value : 0,
      this.props.selectedTeam ? this.props.selectedTeam.value : 0,
    );
  };

  goToNext = () => {
    const { date, onNavigate, userRights } = this.props;
    // date.setMonth(date.getMonth() + 1);
    date.setDate(32);
    const calendarMonth = date.getMonth() + 1;
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();
    if (calendarMonth === currentMonth) {
      date.setDate(currentDay);
    }
    this.fetchEvents(date);
    userRights.includes('hasApproverRights') && this.fetchAnniversaries(date);
    onNavigate('next');
  };

  goToCurrent = () => {
    const { date, onNavigate } = this.props;
    const now = new Date();
    date.setMonth(now.getMonth());
    date.setYear(now.getFullYear());
    onNavigate('current');
  };

  label = () => {
    const { date } = this.props;
    const dt = moment(date);
    return (
      <span>
        <b>{dt.format('MMMM')}</b>
        <span> {dt.format('YYYY')}</span>
      </span>
    );
  };

  handleSelectVacationType = selectedOption => {
    const { date } = this.props;
    this.props.setSelectedVacation(
      selectedOption,
      this.props.selectedStudio,
      this.props.selectedTeam,
      date,
    );
  };

  handleSelectStudio = selectedOption => {
    const { date, userRights } = this.props;
    this.props.setSelectedStudio(
      selectedOption,
      this.props.selectedTeam,
      this.props.selectedVacationType,
      date,
      userRights.includes('hasApproverRights'),
    );
  };

  handleSelectTeam = selectedOption => {
    const { date, userRights } = this.props;
    this.props.setSelectedTeam(
      this.props.selectedStudio,
      selectedOption,
      this.props.selectedVacationType,
      date,
      userRights.includes('hasApproverRights'),
    );
  };

  toggleAnniversaries = () => {
    // toggles between vacations and anniversaries
    this.props.toggleSelectedCalendar();
  };

  render() {
    const { selectedCalendar, userRights } = this.props;

    const toggleCalendarLabel =
      selectedCalendar === CALENDARS.vacations ? 'Show anniversaries' : 'Show vacations';

    return (
      <div className="toolbar-container">
        <Row>
          {userRights.includes('hasApproverRights') && (
            <button className="btn btn-link anniversaries" onClick={this.toggleAnniversaries}>
              {toggleCalendarLabel}
            </button>
          )}
          <Col xs={4}>
            <div className="back-next-buttons">
              <button className="btn btn-back btn-link" onClick={this.goToBack}>
                ❮
              </button>
              <label className="label-date">{this.label()}</label>
              <button className="btn btn-next btn-link" onClick={this.goToNext}>
                ❯
              </button>
            </div>
          </Col>
          <Col xs={8}>
            <Select
              className="away-dropdown"
              classNamePrefix="away"
              placeholder="Vacation type"
              value={this.props.selectedVacationType}
              onChange={this.handleSelectVacationType}
              options={[
                {
                  label: 'All vacations',
                  value: 0,
                },
                ...this.props.vacationTypes,
                {
                  label: 'Working on Holiday',
                  value: -2,
                },
              ]}
              isSearchable={false}
            />
            <Select
              className="away-dropdown"
              classNamePrefix="away"
              placeholder="Studios"
              value={this.props.selectedStudio}
              onChange={this.handleSelectStudio}
              options={[
                {
                  label: 'All Studios',
                  value: 0,
                },
                ...this.props.studios,
              ]}
              isSearchable={false}
            />
            <Select
              className="away-dropdown"
              classNamePrefix="away"
              placeholder="Teams"
              value={this.props.selectedTeam}
              onChange={this.handleSelectTeam}
              options={[
                {
                  label: 'All Teams',
                  value: 0,
                },
                ...this.props.teams,
              ]}
              isSearchable={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  vacationTypes: store.constants.vacationTypes,
  studios: store.constants.studios,
  teams: store.constants.teams,
  selectedVacationType: store.ui.selectedVacationType,
  selectedStudio: store.ui.selectedStudio,
  selectedTeam: store.ui.selectedTeam,
  selectedCalendar: store.ui.selectedCalendar,
  userRights: store.signedInUser.userRights,
});

const mapDispatchToProps = dispatch => ({
  setSelectedStudio: bindActionCreators(setSelectedStudio, dispatch),
  setSelectedVacation: bindActionCreators(setSelectedVacation, dispatch),
  setSelectedTeam: bindActionCreators(setSelectedTeam, dispatch),
  toggleSelectedCalendar: bindActionCreators(toggleSelectedCalendar, dispatch),
  fetchDashboardVacations: bindActionCreators(fetchDashboardVacations, dispatch),
  fetchDashboardAnniversaries: bindActionCreators(fetchDashboardAnniversaries, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Toolbar);
