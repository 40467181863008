import {
  FETCH_OFFICIAL_HOLIDAYS_LIST_START,
  FETCH_OFFICIAL_HOLIDAYS_LIST_SUCCESS,
  FETCH_OFFICIAL_HOLIDAYS_LIST_ERROR,
  ADD_OFFICIAL_HOLIDAY_START,
  ADD_OFFICIAL_HOLIDAY_SUCCESS,
  ADD_OFFICIAL_HOLIDAY_ERROR,
  UPDATE_OFFICIAL_HOLIDAY_START,
  UPDATE_OFFICIAL_HOLIDAY_SUCCESS,
  UPDATE_OFFICIAL_HOLIDAY_ERROR,
  DELETE_OFFICIAL_HOLIDAY_START,
  DELETE_OFFICIAL_HOLIDAY_SUCCESS,
  DELETE_OFFICIAL_HOLIDAY_ERROR,
  REMOVE_FIXED_HOLIDAY,
  REMOVE_FLOATIING_HOLIDAY,
  CLEAN_REQUEST_ERRORS,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  fixedHolidaysList: [],
  floatingHolidaysList: [],
  addHoliday: {
    isFetching: false,
  },
  deleteHoliday: {
    isFetching: false,
    holidayId: 0,
  },
  updateHoliday: {
    isFetching: false,
    holidayId: 0,
  },
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case CLEAN_REQUEST_ERRORS: {
      return {
        ...state,
        addHoliday: { ...state.addHoliday, error: '' },
        updateHoliday: { ...state.updateHoliday, error: '' },
      };
    }

    case FETCH_OFFICIAL_HOLIDAYS_LIST_START:
      return { ...state, isFetching: true };
    case FETCH_OFFICIAL_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        fixedHolidaysList: action.payload.data.fixed,
        floatingHolidaysList: action.payload.data.floating,
        isFetching: false,
      };
    case FETCH_OFFICIAL_HOLIDAYS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case ADD_OFFICIAL_HOLIDAY_START:
      return {
        ...state,
        addHoliday: { isFetching: true },
      };
    case ADD_OFFICIAL_HOLIDAY_SUCCESS:
      const dateType = !!action.payload.data.fixed_date;
      const listToUpdate = dateType ? state.fixedHolidaysList : state.floatingHolidaysList;
      const updatedList = [...listToUpdate];
      updatedList.unshift(action.payload.data);

      const dataToUpdate = dateType
        ? {
            fixedHolidaysList: updatedList,
          }
        : {
            floatingHolidaysList: updatedList,
          };

      return {
        ...state,
        addHoliday: { isFetching: false },
        ...dataToUpdate,
      };
    case ADD_OFFICIAL_HOLIDAY_ERROR:
      return {
        ...state,
        addHoliday: {
          isFetching: false,
          error: action.payload.response.data.message,
        },
      };
    case DELETE_OFFICIAL_HOLIDAY_START: {
      return {
        ...state,
        deleteHoliday: {
          isFetching: true,
          holidayId: action.payload,
        },
      };
    }
    case DELETE_OFFICIAL_HOLIDAY_SUCCESS: {
      return {
        ...state,
        deleteHoliday: {
          isFetching: false,
        },
      };
    }
    case DELETE_OFFICIAL_HOLIDAY_ERROR: {
      return {
        ...state,
        deleteHoliday: {
          isFetching: false,
        },
      };
    }
    case REMOVE_FIXED_HOLIDAY: {
      const list = state.fixedHolidaysList.filter(v => v.id !== action.payload);
      return {
        ...state,
        fixedHolidaysList: list,
      };
    }
    case REMOVE_FLOATIING_HOLIDAY: {
      const list = state.floatingHolidaysList.filter(v => v.id !== action.payload);
      return {
        ...state,
        floatingHolidaysList: list,
      };
    }
    case UPDATE_OFFICIAL_HOLIDAY_START: {
      return {
        ...state,
        updateHoliday: {
          isFetching: true,
          holidayId: action.payload,
        },
      };
    }
    case UPDATE_OFFICIAL_HOLIDAY_SUCCESS: {
      const dateType = !!action.payload.data.fixed_date;
      const listToUpdate = dateType ? state.fixedHolidaysList : state.floatingHolidaysList;
      const updatedList = listToUpdate.map(item => {
        if (item.id === action.payload.data.id) {
          item = action.payload.data;
        }
        return item;
      });

      const dataToUpdate = dateType
        ? {
            fixedHolidaysList: updatedList,
          }
        : {
            floatingHolidaysList: updatedList,
          };

      return {
        ...state,
        updateHoliday: {
          isFetching: false,
        },
        ...dataToUpdate,
      };
    }
    case UPDATE_OFFICIAL_HOLIDAY_ERROR: {
      return {
        ...state,
        updateHoliday: {
          isFetching: false,
        },
      };
    }

    default:
      return state;
  }
};
