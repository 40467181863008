import React from 'react';
import { Row, Col } from 'reactstrap';
import VacationTypeListItem from '../VacationTypesListItem';

const VacationTypesList = ({ vacationTypesList, onEdit, onDelete, onCreate }) => {
  const vacations =
    vacationTypesList &&
    vacationTypesList.length &&
    vacationTypesList.map((vacation, i) => (
      <VacationTypeListItem key={i} vacationData={vacation} onEdit={onEdit} onDelete={onDelete} />
    ));

  return (
    <div className="away-list">
      <div className="list-title-section">
        <h3 className="list-title">Vacation Types</h3>
        {
          //TODO: uncomment when in version 2 and api ready
          /*<button className="btn btn-primary btn-small" onClick={onCreate}>
          Add
        </button>*/
        }
      </div>
      <Row>
        <Col md={{ size: 12 }}>
          <div className="list-header">
            <Row>
              <Col md={{ size: 4 }} className="list-cell">
                <span className="list-cell-text">Name</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Days</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Calendar color</span>
              </Col>
              <Col md={{ size: 2 }} className="list-cell" />
            </Row>
          </div>
        </Col>
      </Row>
      {vacations}
    </div>
  );
};

export default VacationTypesList;
