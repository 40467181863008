import { reset } from 'redux-form';

import {
  SET_SELECTED_VACATION_TYPE,
  SET_SELECTED_STUDIO,
  SET_TEAM_SELECTED_ROLE,
  SET_TEAM_SELECTED_STUDIO,
  SET_PEOPLE_SELECTED_TEAM,
  SET_TEAM_GRID,
  SET_TEAM_PAGE,
  SET_TEAM_ADMIN_PAGE,
  SET_TEAM_ADMIN_SELECTED_STUDIO,
  SET_TEAM_ADMIN_SELECTED_YEAR,
  SET_TEAM_ADMIN_SELECTED_STATUS,
  SET_TEAM_ADMIN_SELECTED_FIELD,
  SET_TEAM_ADMIN_SELECTED_ORDER,
  SET_TEAM_ADMIN_PAGE_SIZE,
  SET_USER_LOGS_PAGE,
  SET_USER_VACATIONS_HISTORY_PAGE,
  TOGGLE_REQUEST_MODAL,
  CLEAN_REQUEST_ERRORS,
  TOGGLE_GENERAL_ALERT,
  SET_GENERAL_ALERT_MESSAGE,
  SET_REQUEST_MODAL_INITIAL_STATE,
  SET_REQUEST_MODAL_VACATION_ID,
  TOGGLE_SELECTED_CALENDAR,
  SET_SELECTED_TEAM,
} from './types';

import { firstDayOfAMonth, lastDayOfAMonth } from '../../utils/Date';
import {
  fetchDashboardVacations,
  fetchDashboardAnniversaries,
  fetchDashboardWFH,
} from '../actions/dashboardVacationsActions';
import { fetchTeamList } from '../actions/teamActions';

export const setRequestModalVacationId = value => dispatch => {
  dispatch({ type: SET_REQUEST_MODAL_VACATION_ID, payload: value });
};

export const toggleRequestModal = () => dispatch => {
  dispatch({ type: CLEAN_REQUEST_ERRORS });
  dispatch({ type: TOGGLE_REQUEST_MODAL });
};

export const resetHolidayModalForm = () => dispatch => {
  dispatch(reset('HolidayModalForm'));
};

export const resetVacationTypeModalForm = () => dispatch => {
  dispatch(reset('VacationTypeModalForm'));
};

export const resetStudioModalForm = () => dispatch => {
  dispatch(reset('StudioModalForm'));
};

export const resetAvatarModalForm = () => dispatch => {
  dispatch(reset('AvatarModalForm'));
};

export const resetRejectVacationRequestModalForm = () => dispatch => {
  dispatch(reset('RejectVacationRequestForm'));
};

export const setRequestModalInitialState = value => dispatch => {
  dispatch({ type: SET_REQUEST_MODAL_INITIAL_STATE, payload: value });
};

export const setSelectedVacation = (
  selectedVacationType,
  selectedStudio,
  selectedTeam,
  date,
) => dispatch => {
  dispatch({ type: SET_SELECTED_VACATION_TYPE, payload: selectedVacationType });
  fetchEvents(date, selectedVacationType, selectedStudio, selectedTeam, dispatch);
};

export const setSelectedStudio = (
  selectedStudio,
  selectedTeam,
  selectedVacationType,
  date,
  hasApproverRights,
) => dispatch => {
  dispatch({ type: SET_SELECTED_STUDIO, payload: selectedStudio });
  fetchEvents(date, selectedVacationType, selectedStudio, selectedTeam, dispatch);
  dispatch(fetchDashboardWFH(selectedStudio));
  hasApproverRights && fetchAnniversaries(date, selectedStudio, selectedTeam, dispatch);
};

export const setSelectedTeam = (
  selectedStudio,
  selectedTeam,
  selectedVacationType,
  date,
  hasApproverRights,
) => dispatch => {
  dispatch({ type: SET_SELECTED_TEAM, payload: selectedTeam });
  fetchEvents(date, selectedVacationType, selectedStudio, selectedTeam, dispatch);
  hasApproverRights && fetchAnniversaries(date, selectedStudio, selectedTeam, dispatch);
};

export const toggleSelectedCalendar = () => dispatch => {
  dispatch({ type: TOGGLE_SELECTED_CALENDAR });
};

const fetchEvents = (date, selectedVacationType, selectedStudio, selectedTeam, dispatch) => {
  const firstDay = firstDayOfAMonth(date);
  const lastDay = lastDayOfAMonth(date);
  dispatch(
    fetchDashboardVacations(
      firstDay,
      lastDay,
      selectedVacationType ? selectedVacationType.value : 0,
      selectedStudio ? selectedStudio.value : 0,
      selectedTeam ? selectedTeam.value : 0,
    ),
  );
};

const fetchAnniversaries = (date, selectedStudio, selectedTeam, dispatch) => {
  const firstDay = firstDayOfAMonth(date);
  const lastDay = lastDayOfAMonth(date);
  dispatch(
    fetchDashboardAnniversaries(
      firstDay,
      lastDay,
      selectedStudio ? selectedStudio.value : 0,
      selectedTeam ? selectedTeam.value : 0,
    ),
  );
};

export const setTeamSelectedRole = (selectedStudio, selectedRole, selectedTeam) => dispatch => {
  dispatch({ type: SET_TEAM_SELECTED_ROLE, payload: selectedRole });

  dispatch(
    fetchTeamList({
      studio_id: selectedStudio ? selectedStudio.value : 0,
      team_id: selectedTeam ? selectedTeam.value : 0,
      role_slug: selectedRole ? selectedRole.slug : 'regular',
      page: 1,
    }),
  );
};

export const setTeamSelectedStudio = (selectedStudio, selectedRole, selectedTeam) => dispatch => {
  dispatch({ type: SET_TEAM_SELECTED_STUDIO, payload: selectedStudio });

  dispatch(
    fetchTeamList({
      studio_id: selectedStudio ? selectedStudio.value : 0,
      team_id: selectedTeam ? selectedTeam.value : 0,
      role_slug: selectedRole ? selectedRole.slug : 'regular',
      page: 1,
    }),
  );
};

export const setPeopleSelectedTeam = (selectedTeam, selectedRole, selectedStudio) => dispatch => {
  dispatch({ type: SET_PEOPLE_SELECTED_TEAM, payload: selectedTeam });

  dispatch(
    fetchTeamList({
      studio_id: selectedStudio ? selectedStudio.value : 0,
      team_id: selectedTeam ? selectedTeam.value : 0,
      role_slug: selectedRole ? selectedRole.slug : 'regular',
      page: 1,
    }),
  );
};

export const setTeamGrid = isGrid => dispatch => {
  dispatch({ type: SET_TEAM_GRID, payload: isGrid });
};

export const setTeamPage = activePageNumber => dispatch => {
  dispatch({ type: SET_TEAM_PAGE, payload: activePageNumber });
};

export const setTeamAdminPage = activePageNumber => dispatch => {
  dispatch({ type: SET_TEAM_ADMIN_PAGE, payload: activePageNumber });
};

export const setTeamAdminPageSize = activePageSize => dispatch => {
  dispatch({ type: SET_TEAM_ADMIN_PAGE_SIZE, payload: activePageSize });
};

export const setTeamAdminSelectedStudio = selectedStudio => dispatch => {
  dispatch({ type: SET_TEAM_ADMIN_SELECTED_STUDIO, payload: selectedStudio });
};

export const setTeamAdminSelectedYear = selectedYear => dispatch => {
  dispatch({ type: SET_TEAM_ADMIN_SELECTED_YEAR, payload: selectedYear });
};

export const setTeamAdminSortBy = (selectedField, selectedOrder) => dispatch => {
  dispatch({ type: SET_TEAM_ADMIN_SELECTED_FIELD, payload: selectedField });
  dispatch({ type: SET_TEAM_ADMIN_SELECTED_ORDER, payload: selectedOrder });
};

export const setTeamAdminSelectedStatus = selectedStatus => dispatch => {
  dispatch({ type: SET_TEAM_ADMIN_SELECTED_STATUS, payload: selectedStatus });
};

export const setUserLogsPage = activePageNumber => dispatch => {
  dispatch({ type: SET_USER_LOGS_PAGE, payload: activePageNumber });
};

export const setUserVacationsHistoryPage = activePageNumber => dispatch => {
  dispatch({ type: SET_USER_VACATIONS_HISTORY_PAGE, payload: activePageNumber });
};

export const setGeneralAlertMessage = (message, type) => dispatch => {
  dispatch({
    type: SET_GENERAL_ALERT_MESSAGE,
    payload: { message, alertType: type },
  });
  if (message) {
    dispatch(toggleGeneralAlert(true));
  }
};

export const toggleGeneralAlert = visible => dispatch => {
  if (visible) {
    dispatch({ type: TOGGLE_GENERAL_ALERT, payload: visible });
    const millisecondsToWait = 15000;
    setTimeout(function() {
      dispatch({ type: TOGGLE_GENERAL_ALERT, payload: { visible: false } });
    }, millisecondsToWait);
  } else {
    dispatch({ type: TOGGLE_GENERAL_ALERT, payload: { visible: false } });
  }
};
