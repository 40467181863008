const validate = values => {
  const errors = [];
  if (values.name === '') {
    errors.name = 'Please enter a holiday name';
  }
  if (values.work_days === '') {
    errors.name = "Please enter the holiday's duration";
  }
  if (values.work_days <= 0) {
    errors.work_days = 'Duration must not be 0';
  }
  if (values.work_days > 2) {
    errors.work_days = 'Duration must not be bigger than 2';
  }
  const durationDecimal = values.work_days - Math.floor(values.work_days);
  if (durationDecimal > 0) {
    errors.work_days = 'The duration must be a fixed number';
  }

  return errors;
};

export default validate;
