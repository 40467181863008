import {
  SET_SELECTED_VACATION_TYPE,
  SET_SELECTED_STUDIO,
  SET_SELECTED_TEAM,
  SET_TEAM_SELECTED_ROLE,
  SET_TEAM_SELECTED_STUDIO,
  SET_PEOPLE_SELECTED_TEAM,
  SET_TEAM_GRID,
  SET_TEAM_PAGE,
  SET_TEAM_ADMIN_PAGE,
  SET_TEAM_ADMIN_SELECTED_STUDIO,
  SET_TEAM_ADMIN_SELECTED_YEAR,
  SET_TEAM_ADMIN_SELECTED_FIELD,
  SET_TEAM_ADMIN_SELECTED_ORDER,
  SET_TEAM_ADMIN_SELECTED_STATUS,
  SET_TEAM_ADMIN_PAGE_SIZE,
  SET_USER_LOGS_PAGE,
  SET_USER_VACATIONS_HISTORY_PAGE,
  TOGGLE_REQUEST_MODAL,
  TOGGLE_GENERAL_ALERT,
  SET_GENERAL_ALERT_MESSAGE,
  SET_REQUEST_MODAL_INITIAL_STATE,
  SET_REQUEST_MODAL_VACATION_ID,
  RESET_UI,
  TOGGLE_SELECTED_CALENDAR,
} from '../actions/types';
import { CALENDARS } from '../../constants';

const initialValues = {
  selectedCalendar: CALENDARS.vacations,
  selectedVacationType: {
    label: 'All vacations',
    value: 0,
  },
  selectedStudio: {
    label: 'All Studios',
    value: 0,
  },
  selectedTeam: {
    label: 'All Teams',
    value: 0,
  },
  selectedTeamRole: {
    label: 'All Roles',
    value: -1,
    slug: 'all',
  },
  selectedTeamStudio: {
    label: 'All Studios',
    value: 0,
  },
  selectedPeopleTeam: {
    label: 'All Teams',
    value: 0,
  },
  selectedTeamAdminStudio: {
    label: 'All Studios',
    value: 0,
  },
  selectedTeamAdminYear: {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  selectedTeamAdminStatus: {
    label: 'Active Users',
    value: 0,
  },
  isGrid: false,
  teamListActivePage: 1,
  teamAdminListActivePage: 1,
  teamAdminListActivePageSize: 50,
  selectedTeamAdminField: 'name',
  selectedTeamAdminOrder: 'asc',
  userLogActivePage: 1,
  userVacationsHistoryActivePage: 1,
  calendarDate: new Date(),
  showRequestModal: false,
  requestModal: {
    showRequestModal: false,
    vacationId: 0,
    initialValues: {
      range: {
        range1: {
          startDate: new Date(),
          endDate: new Date(),
        },
      },
      note: '',
      user: null,
      vacationType: null,
    },
  },
  generalAlert: {
    message: '',
    type: '',
    visible: false,
  },
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case RESET_UI: {
      return {
        ...state,
        calendarDate: new Date(),
        selectedCalendar: CALENDARS.vacations,
        selectedVacationType: {
          label: 'All vacations',
          value: 0,
        },
        selectedStudio: {
          label: 'All Studios',
          value: 0,
        },
        selectedTeam: {
          label: 'All Teams',
          value: 0,
        },
      };
    }
    case SET_REQUEST_MODAL_VACATION_ID: {
      return {
        ...state,
        requestModal: {
          ...state.requestModal,
          vacationId: action.payload,
        },
      };
    }
    case SET_REQUEST_MODAL_INITIAL_STATE: {
      return {
        ...state,
        requestModal: {
          ...state.requestModal,
          initialValues: {
            ...state.initialValues,
            ...action.payload,
          },
        },
      };
    }
    case TOGGLE_GENERAL_ALERT: {
      return {
        ...state,
        generalAlert: {
          ...state.generalAlert,
          visible: action.payload.visible,
        },
      };
    }
    case SET_GENERAL_ALERT_MESSAGE: {
      return {
        ...state,
        generalAlert: {
          ...state.generalAlert,
          message: action.payload.message,
          alertType: action.payload.alertType,
          visible: !!action.payload.message,
        },
      };
    }
    case TOGGLE_REQUEST_MODAL: {
      return {
        ...state,
        requestModal: {
          ...state.requestModal,
          showRequestModal: !state.requestModal.showRequestModal,
          vacationId: state.requestModal.showRequestModal ? 0 : state.requestModal.vacationId,
          initialValues: state.requestModal.showRequestModal
            ? initialValues.requestModal.initialValues
            : { ...state.requestModal.initialValues },
        },
      };
    }

    case SET_SELECTED_VACATION_TYPE:
      return { ...state, selectedVacationType: action.payload };
    case SET_SELECTED_STUDIO:
      return { ...state, selectedStudio: action.payload };
    case SET_SELECTED_TEAM:
      return { ...state, selectedTeam: action.payload };
    case SET_TEAM_SELECTED_ROLE:
      return { ...state, selectedTeamRole: action.payload };
    case SET_TEAM_SELECTED_STUDIO:
      return { ...state, selectedTeamStudio: action.payload };
    case SET_PEOPLE_SELECTED_TEAM:
      return { ...state, selectedPeopleTeam: action.payload };
    case TOGGLE_SELECTED_CALENDAR: {
      const nextCalendar =
        state.selectedCalendar === CALENDARS.vacations
          ? CALENDARS.anniversaries
          : CALENDARS.vacations;
      return { ...state, selectedCalendar: nextCalendar };
    }

    case SET_TEAM_GRID:
      return { ...state, teamListIsGrid: action.payload };
    case SET_TEAM_PAGE:
      return { ...state, teamListActivePage: action.payload };
    case SET_TEAM_ADMIN_PAGE:
      return { ...state, teamAdminListActivePage: action.payload };
    case SET_TEAM_ADMIN_PAGE_SIZE:
      return { ...state, teamAdminListActivePageSize: action.payload };
    case SET_TEAM_ADMIN_SELECTED_STUDIO:
      return { ...state, selectedTeamAdminStudio: action.payload };
    case SET_TEAM_ADMIN_SELECTED_YEAR:
      return { ...state, selectedTeamAdminYear: action.payload };
    case SET_TEAM_ADMIN_SELECTED_FIELD:
      return { ...state, selectedTeamAdminField: action.payload };
    case SET_TEAM_ADMIN_SELECTED_ORDER:
      return { ...state, selectedTeamAdminOrder: action.payload };
    case SET_TEAM_ADMIN_SELECTED_STATUS:
      return { ...state, selectedTeamAdminStatus: action.payload };
    case SET_USER_LOGS_PAGE:
      return { ...state, userLogActivePage: action.payload };
    case SET_USER_VACATIONS_HISTORY_PAGE:
      return { ...state, userVacationsHistoryActivePage: action.payload };
    default:
      return state;
  }
};
