import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody, Row, Col, Container } from 'reactstrap';
import { fetchTeamLeads } from '../../../redux/actions/teamActions';
import { TextInput, Select2, DatePicker, Checkbox } from '../../form';
import validate from './validations';
import { tshirt_sizes } from '../../../constants';

class UserProfileModal extends Component {
  state = {
    showJoinedDatePicker: false,
    showBirthdayDatePicker: false,
  };

  componentDidMount() {
    // todo: move this fetch in app start-up flow, after constants are present
    // and set the role ids dynamically
    this.props.fetchTeamLeads({ role: '2,3,4,5' });
  }

  handleSubmitClicked(data) {
    const { updateUserProfile } = this.props;

    const payload = {
      ...data,
      studio_id: data.studio_id.value,
      contract: data.contract.value,
      team_lead: data.team_lead.value,
      role: data.role.value,
      working_location: data.working_location ? 1 : 0,
      nda: data.nda ? 1 : 0,
      us_visa: data.us_visa ? 1 : 0,
      tshirt_size: data.tshirt_size.value,
    };
    updateUserProfile(payload);
    this.handleCloseModal();
  }

  toggleJoinedPicker = () => {
    this.setState({
      showJoinedDatePicker: !this.state.showJoinedDatePicker,
    });
  };

  toggleBirthdayPicker = () => {
    this.setState({
      showBirthdayDatePicker: !this.state.showBirthdayDatePicker,
    });
  };

  handleCloseModal() {
    this.props.toggleModal();
    this.props.reset();
  }

  render() {
    const {
      isSignedInUser,
      handleSubmit,
      showModal,
      userRights,
      roles,
      studios,
      teamLeads,
      initialValues,
      pristine,
    } = this.props;
    const isEditMode = !!initialValues.id;
    const currentDate = new Date();

    return (
      <Modal
        size="lg"
        className="away-modal"
        isOpen={showModal}
        toggle={this.handleCloseModal.bind(this)}
      >
        <ModalBody className="away-modal-body">
          <div className="away-modal-line">
            <h3>{isEditMode ? 'Edit Profile' : 'Add New User'}</h3>
          </div>
          <Container>
            <Row>
              <Col sm="12" md="6">
                <Field id="name" name="name" label="Name" component={TextInput} />
              </Col>
              <Col sm="12" md="6">
                <Field
                  disabled={!userRights.includes('hasManagementRights')}
                  id="email"
                  name="email"
                  label="Email"
                  component={TextInput}
                />
              </Col>

              {/* Where an admin sets the initial password for a new account */}
              {!isEditMode && userRights.includes('hasManagementRights') && (
                <Col sm="12" md="6">
                  <Field id="password" name="password" label="Password" component={TextInput} />
                </Col>
              )}

              <Col sm="12" md="6">
                <div
                  className="away-daterange-inputs"
                  id="birthday"
                  onClick={this.toggleBirthdayPicker}
                >
                  <Field
                    id="birthday"
                    name="date_of_birth"
                    label="Date of birth"
                    component={DatePicker}
                    showMonthsYears
                    showDatePicker={this.state.showBirthdayDatePicker}
                    togglePicker={this.toggleBirthdayPicker}
                    maxDate={currentDate}
                  />
                </div>
              </Col>
              <Col sm="12" md="6">
                <div
                  className="away-daterange-inputs"
                  id="joinDate"
                  onClick={
                    userRights.includes('hasManagementRights') ? this.toggleJoinedPicker : () => {}
                  }
                >
                  <Field
                    id="joinDate"
                    disabled={!userRights.includes('hasManagementRights')}
                    name="company_joined_at"
                    label="Joined company"
                    component={DatePicker}
                    showMonthsYears
                    showDatePicker={this.state.showJoinedDatePicker}
                    togglePicker={this.toggleJoinedPicker}
                  />
                </div>
              </Col>
              <Col sm="12" md="6">
                <Field
                  id="contractType"
                  isDisabled={!userRights.includes('hasManagementRights')}
                  className="away-dropdown-form version-2"
                  classNamePrefix="away"
                  name="contract"
                  label="Contract type"
                  placeholder="Contract type"
                  component={Select2}
                  options={[
                    { value: 'labour contract', label: 'Labour contract' },
                    { value: 'self-employed', label: 'Self employed' },
                  ]}
                />
              </Col>
              <Col sm="12" md="6">
                <Field
                  isDisabled={!userRights.includes('hasManagementRights')}
                  className="away-dropdown-form version-2"
                  classNamePrefix="away"
                  id="studio"
                  name="studio_id"
                  label="Studio"
                  placeholder="Studio"
                  component={Select2}
                  options={studios}
                />
              </Col>
              <Col sm="12" md="6">
                <Field
                  isDisabled={!userRights.includes('hasManagementRights')}
                  className="away-dropdown-form version-2"
                  classNamePrefix="away"
                  id="role"
                  name="role"
                  label="Role"
                  placeholder="Role"
                  component={Select2}
                  options={roles}
                />
              </Col>
              <Col sm="12" md="6">
                <Field
                  isDisabled={!userRights.includes('hasManagementRights')}
                  className="away-dropdown-form version-2"
                  classNamePrefix="away"
                  id="teamLead"
                  name="team_lead"
                  label="Approver"
                  placeholder="Approver"
                  component={Select2}
                  options={teamLeads}
                />
              </Col>
              <Col sm="12" md="6">
                <Field id="skypeId" name="skype_id" label="Skype ID" component={TextInput} />
              </Col>
              <Col sm="12" md="6">
                <Field
                  id="phoneNumber"
                  name="phone_number"
                  label="Phone number"
                  component={TextInput}
                />
              </Col>
              <Col sm="12" md="6">
                <Field id="jobPosition" name="job" label="Job position" component={TextInput} />
              </Col>

              <Col sm="12" md="6">
                <Field
                  id="workingFromHome"
                  name="working_location"
                  label="Working from home"
                  component={Checkbox}
                />
              </Col>

              <Col sm="12" md="6">
                <Field
                  id="tshirt_size"
                  className="away-dropdown-form version-2"
                  classNamePrefix="away"
                  name="tshirt_size"
                  label="T-shirt size"
                  placeholder="Your T-shirt size"
                  component={Select2}
                  options={tshirt_sizes}
                />
              </Col>

              <Col sm="12" md="6">
                <Field id="us_visa" name="us_visa" label="US Visa" component={Checkbox} />
              </Col>

              {isEditMode &&
                (userRights.includes('hasManagementRights') ||
                  userRights.includes('hasAdministrativeRights')) && (
                  <Col sm="12" md="6">
                    <Field id="nda" name="nda" label="NDA" component={Checkbox} />
                  </Col>
                )}

              {/* Only admins can delete (block) an account */}
              {isEditMode && userRights.includes('hasManagementRights') && !isSignedInUser && (
                <Col sm="12" md="6">
                  <Field
                    id="accountBlocked"
                    name="blocked"
                    label="Account blocked"
                    component={Checkbox}
                  />
                </Col>
              )}
            </Row>
            <Row className="justify-content-center">
              <div className="away-modal-buttons">
                <Button
                  onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
                  className="btn btn-primary"
                  disabled={pristine}
                >
                  {isEditMode ? 'Update profile' : 'Add user'}
                </Button>
                <Button onClick={this.handleCloseModal.bind(this)} className="btn-link btn-grey">
                  Cancel
                </Button>
              </div>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

const UserProfileForm = reduxForm({
  validate,
  enableReinitialize: true,
})(UserProfileModal);

const mapStoreToProps = (store, ownProps) => {
  const teamLeadOptions = store.team.teamLeads.map(user => ({
    value: user.id,
    label: user.name,
  }));

  const dob = ownProps.initialValues.date_of_birth;
  const formattedDob = dob ? new Date(dob.valueOf() + dob.getTimezoneOffset() * 60 * 1000) : dob;

  const joinedDate = ownProps.initialValues.company_joined_at;
  const formattedJoinDate = joinedDate
    ? new Date(joinedDate.valueOf() + joinedDate.getTimezoneOffset() * 60 * 1000)
    : joinedDate;

  return {
    userRights: store.signedInUser.userRights,
    studios: store.constants.studios,
    roles: store.constants.roles,
    initialValues: {
      ...ownProps.initialValues,
      tshirt_size: ownProps.initialValues.tshirt_size
        ? tshirt_sizes.find(size => size.value === ownProps.initialValues.tshirt_size)
        : tshirt_sizes[0],
      date_of_birth: formattedDob,
      company_joined_at: formattedJoinDate,
    },
    teamLeads: teamLeadOptions,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTeamLeads: bindActionCreators(fetchTeamLeads, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(UserProfileForm);
