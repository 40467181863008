import {
  // FETCH_CONSTANTS_START,
  FETCH_CONSTANTS_SUCCESS,
  // FETCH_CONSTANTS_ERROR
} from '../actions/types';
import moment from 'moment';

const availableMonths = moment.months().map((month, i) => ({ id: ++i, name: month }));

const initialValues = {
  vacationTypes: [],
  studios: [],
  teams: [],
  roles: [],
  availableYearStatistics: [],
  availableMonthStatistics: [],
  upcomingOfficialHolidays: [],
  constantsFetched: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_CONSTANTS_SUCCESS:
      return {
        ...state,
        vacationTypes: action.payload.vacationTypes.map(type => {
          return { value: type.id, label: type.name, color: type.color };
        }),
        studios: action.payload.studios.map(type => {
          return { value: type.id, label: type.name };
        }),
        teams: action.payload.teams.map(team => {
          return { value: team.id, label: team.name };
        }),
        roles: action.payload.roles.map(type => {
          return { value: type.id, label: type.description, slug: type.slug };
        }),
        availableYearStatistics: action.payload.availableYearStatistics.map(type => {
          return { value: type, label: type };
        }),
        availableMonthStatistics: availableMonths.map(type => {
          return { value: type.id, label: type.name };
        }),
        upcomingOfficialHolidays: action.payload.upcomingOfficialHolidays,
        constantsFetched: true,
      };
    default:
      return state;
  }
};
