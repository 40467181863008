import {
  FETCH_SIGNEDIN_USER_START,
  FETCH_SIGNEDIN_USER_SUCCESS,
  FETCH_SIGNEDIN_USER_ERROR,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PASSWORD_START,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_WORKING_LOCATION_START,
  UPDATE_USER_WORKING_LOCATION_SUCCESS,
  UPDATE_USER_WORKING_LOCATION_ERROR,
  UPDATE_AVATAR_START,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_ERROR,
  DELETE_AVATAR_START,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_ERROR,
  SET_USER_RIGHTS,
} from './types';
import { getData, putData, baseURL, postData, deleteData } from './api';
import { setGeneralAlertMessage, setSelectedStudio } from '../actions/uiActions';
import { reset } from 'redux-form';
import { userPermissions } from '../../constants';
import { processApiError } from '../../utils/ErrorHandler';

export const fetchSignedInUser = () => dispatch => {
  dispatch({ type: FETCH_SIGNEDIN_USER_START });
  const params = {};

  return getData(
    `${baseURL}api/user/profile`,
    params,
    FETCH_SIGNEDIN_USER_SUCCESS,
    FETCH_SIGNEDIN_USER_ERROR,
    dispatch,
    data => {
      const userRights = userPermissions[data.user.role_slug];
      dispatch({ type: SET_USER_RIGHTS, payload: userRights });
      dispatch(
        setSelectedStudio(
          {
            label: data.user.studio.name,
            value: data.user.studio.id,
          },
          0,
          0,
          new Date(),
          userRights.includes('hasApproverRights'),
        ),
      );
    },
  );
};

export const updateSignedInUserProfile = (data, successCallback) => dispatch => {
  dispatch({ type: UPDATE_USER_PROFILE_START });
  return putData(
    `${baseURL}api/user/profile/${data.id}`,
    data,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Profile updated', 'success'));
      successCallback();
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const updateSignedInUserWorkingLocation = (id, data, successCallback) => dispatch => {
  dispatch({ type: UPDATE_USER_WORKING_LOCATION_START });
  return postData(
    `${baseURL}/api/user/profile/${id}/setWorkingLocation`,
    data,
    UPDATE_USER_WORKING_LOCATION_SUCCESS,
    UPDATE_USER_WORKING_LOCATION_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Working location updated', 'success'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const updateSignedInUserPassword = (
  data,
  userId,
  successCallback,
  errorCallback,
) => dispatch => {
  dispatch({ type: UPDATE_USER_PASSWORD_START });
  return putData(
    `${baseURL}api/user/profile/${userId}/password`,
    data,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Password updated', 'success'));
      successCallback();
    },
    error => {
      errorCallback(processApiError(error));
    },
  );
};

export const updateAvatar = (data, userId) => dispatch => {
  dispatch({ type: UPDATE_AVATAR_START });
  return postData(
    `${baseURL}api/user/profile/${userId}/avatar`,
    data,
    UPDATE_AVATAR_SUCCESS,
    UPDATE_AVATAR_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Avatar updated', 'info'));
      dispatch(reset('AvatarModalForm'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const deleteAvatar = userId => dispatch => {
  dispatch({ type: DELETE_AVATAR_START, payload: userId });
  deleteData(
    `${baseURL}api/user/profile/${userId}/avatar`,
    DELETE_AVATAR_SUCCESS,
    DELETE_AVATAR_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Avatar deleted', 'info'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};
