import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FixedHolidays from './components/FixedHolidays';
import FloatingHolidays from './components/FloatingHolidays';
import HolidayModal from './components/HolidayModal';
import CustomLoader from '../../../../components/ui/Loader';
import ConfirmModal from '../../../../components/modals/ConfirmModal';

import {
  fetchOfficialHolidaysList,
  deleteOfficialHoliday,
} from '../../../../redux/actions/officialHolidaysSettingsActions';

class HolidaysSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHolidayModal: false,
      showDeleteModal: false,
      isEditMode: false,
      selectedHoliday: {
        id: 0,
        name: '',
        start_date: new Date(),
        fixed_date: 0,
        work_days: 0,
      },
      holidayId: 0,
      holidayType: 0,
    };

    this.toggleModal = this.toggleModal.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const { fetchOfficialHolidaysList } = this.props;
    fetchOfficialHolidaysList();
  }

  toggleModal() {
    this.setState({
      showHolidayModal: !this.state.showHolidayModal,
    });
  }

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  handleCreate(type) {
    this.setState({
      ...this.state,
      isEditMode: false,
      selectedHoliday: {
        id: 0,
        name: '',
        start_date: new Date(),
        fixed_date: type,
        work_days: 0,
      },
    });
    this.toggleModal();
  }

  handleEdit = data => {
    this.setState({
      ...this.state,
      isEditMode: true,
      selectedHoliday: {
        id: data.id,
        name: data.name,
        work_days: data.work_days,
        start_date: new Date(data.start_date),
        fixed_date: data.fixed_date,
      },
    });
    this.toggleModal();
  };

  handleDelete = data => {
    this.setState({
      ...this.state,
      holidayId: data.id,
      holidayType: data.fixed_date,
    });
    this.toggleDeleteModal();
  };

  onConfirmDelete = () => {
    this.toggleDeleteModal();
    this.props.deleteOfficialHoliday(this.state.holidayId, this.state.holidayType);
  };

  render() {
    const { isEditMode, showHolidayModal, selectedHoliday } = this.state;
    const { fixedHolidaysList, floatingHolidaysList, isFetching } = this.props;

    if (isFetching) {
      return <CustomLoader />;
    }

    return (
      <div>
        <FixedHolidays
          holidayList={fixedHolidaysList}
          onCreate={this.handleCreate}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
        />
        <div className="away-list-divider" />
        <FloatingHolidays
          holidayList={floatingHolidaysList}
          onCreate={this.handleCreate}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
        />
        <HolidayModal
          showModal={showHolidayModal}
          toggleModal={this.toggleModal}
          edit={isEditMode}
          initialValues={{
            id: selectedHoliday.id,
            name: selectedHoliday.name,
            work_days: selectedHoliday.work_days,
            start_date: selectedHoliday.start_date,
            fixed_date: selectedHoliday.fixed_date,
          }}
        />
        <ConfirmModal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
          header="Delete holiday"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  fixedHolidaysList: store.officialHolidaysSettings.fixedHolidaysList,
  floatingHolidaysList: store.officialHolidaysSettings.floatingHolidaysList,
  isFetching: store.officialHolidaysSettings.isFetching,
});

const mapDispatchToProps = dispatch => ({
  deleteOfficialHoliday: bindActionCreators(deleteOfficialHoliday, dispatch),
  fetchOfficialHolidaysList: bindActionCreators(fetchOfficialHolidaysList, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(HolidaysSettings);
