import isEmail from 'sane-email-validation';

const validate = values => {
  const errors = [];
  const currentDate = new Date();
  if (!values.company_joined_at) {
    errors.company_joined_at = 'Please select a hiring date';
  }
  if (!values.contract) {
    errors.contract = 'Please select a contract type';
  }
  if (!values.email) {
    errors.email = 'Please enter an email address';
  } else if (!isEmail(values.email)) {
    errors.email = 'Please enter a valid email address';
  }
  if (!values.job) {
    errors.job = 'Please enter a job position';
  }
  if (!values.name) {
    errors.name = 'Please enter a name';
  }
  if (!values.role) {
    errors.role = 'Please select a role';
  }
  if (!values.password) {
    errors.password = 'Please enter a password';
  } else if (values.password.length < 8) {
    errors.password = 'The password must have at least 8 characters';
  }

  if (!values.date_of_birth) {
    errors.date_of_birth = 'Please select a date of birth';
  } else if (values.date_of_birth.getFullYear() > currentDate.getFullYear() - 18) {
    errors.date_of_birth = 'User must be at least 18 years old';
  }

  if (!values.phone_number) {
    errors.phone_number = 'Please enter a phone number';
  } else if (!/^[- +()]*[0-9][- +()0-9]*$/.test(values.phone_number)) {
    errors.phone_number = 'Please enter a valid phone number';
  } else if (values.phone_number.length < 6) {
    errors.phone_number = 'Phone number should be at least 6 characters';
  }
  if (!values.skype_id) {
    errors.skype_id = 'Please enter a valid skype ID';
  }
  if (!values.studio_id) {
    errors.studio_id = 'Please select a studio';
  }
  if (!values.team_lead) {
    errors.team_lead = 'Please select a team lead';
  }

  return errors;
};

export default validate;
